import React from 'react'
import Container from '@/components/Container'
import MintCloud from '@/assets/MintCloud'

export interface ContainerProps {
  children: React.ReactNode
  className?: string
  classNameInner?: string
  defaultWidth?: boolean
  color?: string
  block?: string
  side?: string
}

const CloudContainer = ({
  children,
  className = '',
  classNameInner = '',
  color = 'hide',
  block = '',
  defaultWidth = true,
  side = '',
}: ContainerProps) => {
  // Style Groups
  const cloudColor = color == 'mint' ? 'cloud-mint' : color == 'blue' ? 'cloud-blue' : 'hide'
  const WysiwygSides =
    side == 'right'
      ? 'rotate-[160deg] top-[65px] hidden lg:block lg:right-[-400px] xl:right-[-280px] 2xl:right-[-180px] w-[500px] md:w-[500px] lg:w-[500px]'
      : 'top-[65px] hidden lg:block lg:left-[-410px] xl:left-[-280px] 2xl:left-[-180px] w-[500px] md:w-[500px] lg:w-[500px]'
  const TextImageSides =
    side == 'right'
      ? 'rotate-[160deg] top-[unset] bottom-[-50px] sm:bottom-[unset] sm:top-[-50px] md:top-[-100px] right-[-100px] sm:right-[-100px] md:right-[-250px] lg:right-[-260px] xl:right-[-280px] w-[250px] md:w-[400px] lg:w-[500px]'
      : 'rotate-[290deg] top-[-50px] sm:top-[-50px] md:top-[-80px] left-[-100px] sm:left-[-100px] md:left-[-250px] lg:left-[-260px] xl:left-[-280px] w-[250px] md:w-[380px] lg:w-[460px]'

  // Cloud locked to container
  const cloudNarrowClasses =
    block === 'front-page-text-image'
      ? `${cloudColor} absolute sm:top-[-40px] bottom-[-50px] right-[-100px] md:top-[unset] sm:bottom-[unset] md:bottom-[-80px] lg:bottom-[-150px] sm:right-[-100px] md:right-[-250px] lg:right-[-260px] xl:right-[-280px] w-[250px] md:w-[400px] lg:w-[500px]`
      : block === 'text-image'
      ? `${cloudColor} absolute ${TextImageSides}`
      : ''
  // Cloud locked to full width container
  const cloudWideClasses = block === 'wysiwyg' ? `${cloudColor} absolute ${WysiwygSides}` : ''

  return (
    <div className={`w-full relative ${className}`}>
      {cloudColor !== 'hide' && block && cloudWideClasses !== '' ? (
        <div className={cloudWideClasses}>
          <MintCloud />
        </div>
      ) : null}
      <Container defaultWidth={defaultWidth} classNameInner={`relative ${classNameInner}`}>
        {cloudColor !== 'hide' && block && cloudNarrowClasses !== '' ? (
          <div className={cloudNarrowClasses}>
            <MintCloud />
          </div>
        ) : null}
        {children}
      </Container>
    </div>
  )
}

export default CloudContainer
