import React, { useState } from 'react'
import GoogleMapReact from 'google-map-react'
import imagemarker from './imagemarker.png'

const AnyReactComponent = ({ text = '', lat, lng }: any) => {
  const [open, setOpen] = useState(false)

  return (
    <div>
      <div className="" onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
        <img alt="Marker" src={imagemarker} />
      </div>
      {open && (
        <div className="absolute bg-white p-4">
          <div className="leading-2 g-info-box" dangerouslySetInnerHTML={{ __html: text }} />
        </div>
      )}
    </div>
  )
}

export default function GMap({ markers, poslat, poslng, zoom }: any) {
  const mapSettings = {
    center: { lat: parseFloat(poslat) || 60.16, lng: parseFloat(poslng) || 24.85 },
    zoom: parseFloat(zoom) || 12,
  }

  return (
    <div style={{ height: '450px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyAJVnsNRh7E-uEhJ5ateRokZqpQ17a5rmc' }}
        defaultCenter={mapSettings.center}
        defaultZoom={mapSettings.zoom}
      >
        {markers &&
          markers.map((marker: any, index: number) => {
            if (marker.lat && marker.long) {
              return (
                <AnyReactComponent
                  key={index}
                  lat={parseFloat(marker.lat)}
                  lng={parseFloat(marker.long)}
                  text={marker.locationContent}
                />
              )
            }
          })}
      </GoogleMapReact>
    </div>
  )
}
