import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import Layout from '@/components/Layout'
import Container from '@/components/Container'
import PageHeading from '@/components/PageHeading'
import Location from '@/components/Location'
import CloudContainer from '@/components/CloudContainer'
import { seo, wp, pagelang } from '@/types.d'
import Onview from '@/components/Onview'
import GMap from '@/components/GMap'
import { useTranslation } from 'gatsby-plugin-react-i18next'

interface ContactTemplateData {
  data: {
    page: {
      id: string
      title: string
      CloudContainer: boolean
      uri: string
      seo: seo
      language: pagelang
      translations: any
      featuredImage: any
      mapShow: any
      mapLat: string
      mapLng: string
      mapZoom: string
    }
    form: any
    locations: {
      nodes: any
    }
    wp: wp
    mainMenu: any
    instagram: any
  }
}

const ContactTemplate = ({ data }: ContactTemplateData) => {
  const { page, locations, mainMenu, instagram, wp, form } = data
  mainMenu.navButton = wp?.navButtons || ''
  const langData = { languages: wp.languages, currentLang: page.language.slug, translations: page.translations }

  const { t, i18n } = useTranslation()

  useEffect(() => {
    i18n.changeLanguage(page?.language?.slug)
  }, [])

  return (
    <Layout
      form={form}
      featuredImage={page?.featuredImage}
      nav={mainMenu}
      lang={langData}
      instagram={instagram}
      cloudLeft={true}
      uri={page?.uri}
      seodesc={page?.seo?.metaDesc}
      seokeywors={page?.seo?.metaKeywords}
      seotitle={page?.seo?.title}
    >
      <Container defaultWidth={false} classNameInner="max-w-[800px]" className="mt-20 mb-20 md:mt-24 md:mb-16">
        <PageHeading className="text-center">{page.title}</PageHeading>
      </Container>
      <Container defaultWidth={false} classNameInner="max-w-[950px]" className="my-20 md:my-24">
        <Onview>
          <div className="grid sm:grid-cols-2 gap-2">
            {locations?.nodes.map((kiosk: any) => {
              return (
                <Location
                  address={kiosk?.locationContent}
                  heading={kiosk?.title}
                  key={kiosk?.id}
                  openinghours={kiosk?.otherContent}
                  image={kiosk?.featuredImage}
                  ribbon={kiosk?.ribbon}
                  ribbonText={t('Yhteistyökumppani')}
                />
              )
            })}
          </div>
        </Onview>
      </Container>
      {page?.mapShow && (
        <CloudContainer
          defaultWidth={false}
          classNameInner="max-w-[950px]"
          className="mt-16 mb-20 md:mt-16 md:mb-28"
          block="text-image"
          color="mint"
          side="right"
        >
          <Onview>
            <GMap markers={locations?.nodes} poslat={page?.mapLat} poslng={page?.mapLng} zoom={page?.mapZoom} />
          </Onview>
        </CloudContainer>
      )}
    </Layout>
  )
}

export default ContactTemplate

export const ContactTemplateQuery = graphql`
  query Contactpage($id: String!, $menu: [WpMenuLocationEnum], $translang: String, $footerForm: Int) {
    page: wpPage(id: { eq: $id }) {
      ...ContactTemplateFields
      ...featuredImagePage
      ...langdata
      ...seo
    }
    locations: allWpGwLocationsLocation(
      sort: { order: ASC, fields: menuOrder }
      filter: { language: { slug: { eq: $translang } } }
    ) {
      nodes {
        title
        uri
        id
        ribbon: gwLocationRibbon
        lat: gwLocationsCoordLat
        long: gwLocationsCoordLong
        locationContent: gwLocationsLocation
        otherContent: gwLocationsOther
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(aspectRatio: 1.2, height: 640, transformOptions: { cropFocus: CENTER }, quality: 100)
              }
            }
          }
        }
      }
    }
    mainMenu: wpMenu(locations: { in: $menu }) {
      ...navigation
    }
    wp {
      ...allLang
      ...subscribe
    }
    instagram: allInstagramContent(limit: 5) {
      ...instagram
    }
    locales: allLocale(filter: { language: { eq: $translang } }) {
      ...stringTranslations
    }
    form: wpGravityFormsForm(formId: { eq: $footerForm }) {
      ...GravityFormFields
    }
  }
`
