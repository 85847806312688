import React from 'react'
import Image from '@/components/Image'
import Wysiwyg from '@/components/Wysiwyg'
import { getImage } from 'gatsby-plugin-image'
import kiosk from '@/assets/kiosk.png'

export interface LocationData {
  heading?: string
  address?: React.ReactNode
  openinghours?: React.ReactNode
  className?: string
  link?: any
  image: any
  ribbon?: boolean
  ribbonText?: string
}

const Location = ({ heading, className = '', image, address, openinghours, ribbonText, ribbon }: LocationData) => {
  const alt = image?.node?.altText || 'location'
  const img: any = getImage(image?.node?.localFile) || null

  return (
    <div className={`mb-14 relative ${className}`}>
      <div className="relative">
        {image ? (
          <Image className="w-full" image={img} alt={alt} />
        ) : (
          <img alt="Helsinginjäätelötehtaan kioski" src={kiosk} />
        )}
        {ribbon && (
          <div className="absolute bottom-5 left-0 bg-primary text-white px-3 py-1 font-bold">
            <p>{ribbonText}</p>
          </div>
        )}
      </div>
      <div className="flex sm:flex-row flex-col text-center mt-6 sm:mt-8">
        <div className="w-full sm:w-1/2 flex-grow-0 flex-shrink-0 text-left sm:text-center">
          <h2 className="text-primary text-2xl">{heading}</h2>
          <Wysiwyg className="location">{address}</Wysiwyg>
        </div>
        <div className="w-full sm:w-1/2 flex-grow-0 flex-shrink-0 mt-6 sm:mt-0 text-left sm:text-center">
          <h3 className="text-primary text-xl">Aukioloajat</h3>
          <Wysiwyg className="location">{openinghours}</Wysiwyg>
        </div>
      </div>
    </div>
  )
}

export default Location
