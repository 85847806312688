import React from 'react'

export interface PageHeadingData {
  children: string
  className?: string
}

const PageHeading = ({ children, className = '' }: PageHeadingData) => {
  return (
    <div className={`mb-6 ${className}`}>
      <h1>{children}</h1>
    </div>
  )
}

export default PageHeading
